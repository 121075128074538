// Codifications that can be retrieved using web service /getCodifs.htm
// See ticket SAN-15
// See https://mobile-spot.atlassian.net/wiki/spaces/FR/pages/18677787/UserData+Networking+WebServices+request
var CODIFICATIONS_SOURCES = {
  EXHIBIS: 'EXHIBIS'
};
var CODIFICATIONS_ENTITIES = {
  INDIVIDUEVT: 'IndividuEvt'
};
var CODIFICATIONS_NAMES = {
  FONCTION: 'ZL_FONCTION_INFIRMIER',
  // 'ZL_FONCTION',
  PROFIL: 'ZL_TYPE',
  // 'ZL_PROFIL',
  SERVICE: 'ZL_SPECIALITE_INFIRMIER',
  // 'ZL_SERVICE',
  // CENTREINTERET: 'ZL_CENTREINTERET',
  STATUS: 'ZL_STATUT_INFIRMIER',
  ACTIVITY: 'ZL_SecteurActiviteSI',
  FORMATION: 'ZL_FORMATION'
};
var CODIFICATION_FONCTION = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.FONCTION
};
var CODIFICATION_PROFIL = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.PROFIL
};
var CODIFICATION_SERVICE = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.SERVICE
};
var CODIFICATION_CENTREINTERET = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.CENTREINTERET
};
var CODIFICATION_FORMATION = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.FORMATION
};
var CODIFICATION_STATUS = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.STATUS
};
var CODIFICATION_ACTIVITY = {
  sourceCode: CODIFICATIONS_SOURCES.EXHIBIS,
  entityCode: CODIFICATIONS_ENTITIES.INDIVIDUEVT,
  nameField: CODIFICATIONS_NAMES.ACTIVITY
};
var CODIFICATIONS_TO_AUTOMATICALLY_RETRIEVE = [CODIFICATION_FONCTION, CODIFICATION_PROFIL, CODIFICATION_SERVICE, // CODIFICATION_CENTREINTERET,
CODIFICATION_ACTIVITY, CODIFICATION_STATUS, CODIFICATION_FORMATION];
var FETCH_ON_LOGIN_SUCCESS = true;
module.exports = {
  CODIFICATION_FONCTION: CODIFICATION_FONCTION,
  CODIFICATION_PROFIL: CODIFICATION_PROFIL,
  CODIFICATION_SERVICE: CODIFICATION_SERVICE,
  CODIFICATION_CENTREINTERET: CODIFICATION_CENTREINTERET,
  CODIFICATIONS_TO_AUTOMATICALLY_RETRIEVE: CODIFICATIONS_TO_AUTOMATICALLY_RETRIEVE,
  FETCH_ON_LOGIN_SUCCESS: FETCH_ON_LOGIN_SUCCESS,
  CODIFICATION_FORMATION: CODIFICATION_FORMATION,
  CODIFICATION_STATUS: CODIFICATION_STATUS,
  CODIFICATION_ACTIVITY: CODIFICATION_ACTIVITY
};