import { HOME_PAGE_KEY, KLIPSO_LEADS_PAGE_KEY } from 'src/pages/pagesKeys';

function blackText() {
  global.StatusBar.styleDefault();
}

function whiteText() {
  global.StatusBar.styleLightContent();
}

export default function configureStatusBar(pageKey) {
  // doc: https://github.com/apache/cordova-plugin-statusbar/
  global.StatusBar.overlaysWebView(false);

  switch (pageKey) {
    case HOME_PAGE_KEY:
      blackText();
      global.StatusBar.backgroundColorByHexString('#ffffff');
      break;

    case KLIPSO_LEADS_PAGE_KEY:
      blackText();
      global.StatusBar.backgroundColorByHexString('#336F7B');
      break;

    default:
      blackText();
      global.StatusBar.backgroundColorByHexString('#ffffff');
  }
}