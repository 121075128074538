var defaultConf = require('./config-default');

var BO_URL = 'si-data-dev.mobile-spot.com';
var BO_TAIGA_URL = 'https://pgorganisation2021.site.calypso-event.net';
var NODE_BACKEND_DOMAIN = 'si-node-backend-dev.mobile-spot.com';
var NODE_WS_BAKEND_DOMAIN = 'si-node-backend-ws-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL: BO_URL,
  // no trailing '/'
  BO_PATH: '/home/www/pgorganisation/si/si2020/si-data-dev.mobile-spot.com',
  // no trailing '/'
  BO_SERVER_ALIAS: 'kim1',
  DEPLOY_PATH: '/home/www/pgorganisation/si/si2020/si-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-48506479-30',
  LOGIN: {
    forgottenPasswordURL: "".concat(BO_TAIGA_URL, "/mobile-si/mot-de-passe-oublie.htm")
  },
  projectId: '603415879336',
  // FCM sender id
  appId: 'A6B03-07BE0',
  // pushwoosh app id
  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  CODEPUSH: {
    FEATURE_ENABLED: false
  },
  SOCIAL: {
    FEATURE_ENABLED: true,
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    URL: "https://".concat(NODE_BACKEND_DOMAIN, "/social")
  },
  REAL_TIME: {
    URL: "https://".concat(NODE_WS_BAKEND_DOMAIN)
  }
});